<template>
	<div class="page">
		<Head :headTitle="headTitle" />
		<div class="b-container">
			<div class="con-l">
				<div class="mod1 flex flex-v">
					<div class="b-pub-tt flex">
						<span class="bt">游客流量</span>
					</div>
					<div class="b-smtit">
						<span class="bt">客流统计(人)</span>
					</div>
					<ul class="list">
						<li>
							<img src="../assets/images/wlv_04.png" class="icon" alt="">
							<div class="info">
								<h6 class="tit">景区人数</h6>
								<p class="num" style="color:#489FFE;">8,343</p>
							</div>
						</li>
						<li>
							<img src="../assets/images/wlv_05.png" class="icon" alt="">
							<div class="info">
								<h6 class="tit">文化馆人数</h6>
								<p class="num" style="color:#85B1FF;">7,344</p>
							</div>
						</li>
						<li>
							<img src="../assets/images/wlv_06.png" class="icon" alt="">
							<div class="info">
								<h6 class="tit">图书馆人数</h6>
								<p class="num" style="color:#90FFAD;">2,886</p>
							</div>
						</li>
						<li>
							<img src="../assets/images/wlv_07.png" class="icon" alt="">
							<div class="info">
								<h6 class="tit">博物馆人数</h6>
								<p class="num" style="color:#FFC253;">3,886</p>
							</div>
						</li>
					</ul>
					<div class="b-smtit">
						<span class="bt">客流统计(人)</span>
					</div>
					<div class="chart flex-11" ref="chart1"></div>
				</div>
				<div class="mod2 flex flex-v">
					<div class="b-pub-tt flex">
						<span class="bt">游客画像</span>
					</div>
					<ul class="list flex-11">
						<li class="item">
							<div class="b-smtit">
								<span class="bt">游客客源地排行</span>
							</div>
							<div class="chart" ref="chart2"></div>
						</li>
						<li class="item">
							<div class="b-smtit">
								<span class="bt">性别分布</span>
							</div>
							<div class="chart" ref="chart3"></div>
						</li>
						<li class="item">
							<div class="b-smtit">
								<span class="bt">年龄分布</span>
							</div>
							<div class="bar-list fenbu">
								<div class="row" v-for="(item,index) in fbList" :key="index">
									<div class="smbox">
										<div class="font">
											<span>{{item.name}}</span>
											<span>{{item.num}}</span> 
										</div>
										<div class="progress"><i :style="{'width': item.widthVar}"></i></div>
									</div>
								</div>
							</div>
						</li>
						<li class="item">
							<div class="b-smtit">
								<span class="bt">消费统计</span>
							</div>
							<div class="chart" ref="chart4"></div>
						</li>
					</ul>
					
				</div>
			</div>
			<div class="con-c flex flex-v relative">
				<div class="mod-cen mod3">
					<div class="cen-menu">
						<span class="lk">智慧城市</span>
						<span class="lk">数字乡村振兴</span>
						<span class="lk">智慧溯源</span>
						<span class="lk cur">智慧文旅</span>
					</div>
					<div class="cen-top">
						<li>
							<h6 class="tit">游客画像</h6>
						</li>
						<li class="line"></li>
						<li>
							<h6 class="tit">月累计游客(人)</h6>
							<p class="num">669，750</p>
						</li>
						<li class="line"></li>
						<li>
							<h6 class="tit">年累计游客(人)</h6>
							<p class="num">3，346，000</p>
						</li>
						<li class="line"></li>
						<li>
							<h6 class="tit">人均消费(元/人)</h6>
							<p class="num">1，256</p>
						</li>
					</div>
					<div class="mod3-map">
						<img src="../assets/images/wlv_25.jpg" class="pic" alt="">
						<div class="item item1">
							<img src="../assets/images/wlv_17.png" class="icon" alt="">
							<div class="box">
								<div class="imgbox">
									<img src="../assets/images/wlv_09.webp" class="img" alt="">
								</div>
								<div class="info">
									<h6 class="title">风景名胜区道路</h6>
									<div class="txt">
										<p>安全等级:<em class="c-org">三级</em></p>
										<p>检查记录:无异常</p>
										<p>扣分情况:不扣分</p>
									</div>
								</div>
							</div>
						</div>
						<div class="item item2">
							<img src="../assets/images/wlv_18.png" class="icon" alt="">
							<div class="box">
								<div class="imgbox">
									<img src="../assets/images/wlv_09.webp" class="img" alt="">
								</div>
								<div class="info">
									<h6 class="title">风景道路同行状况</h6>
									<div class="txt">
										<p>拥堵指数:<em class="c-org">同行良好/8.9</em></p>
										<p>在途车辆:65</p>
										<p>平均通行速度:1.7S/辆</p>
									</div>
								</div>
							</div>
						</div>
						<div class="item item3">
							<img src="../assets/images/wlv_19.png" class="icon" alt="">
							<div class="box">
								<div class="imgbox">
									<img src="../assets/images/wlv_09.webp" class="img" alt="">
								</div>
								<div class="info">
									<h6 class="title">景区酒店入住情况</h6>
									<div class="txt">
										<p>入住率:<em class="c-org">80%入住率</em></p>
										<p>入住人员:5000</p>
										<p>平均入住时间:1.5天/人</p>
									</div>
								</div>
							</div>
						</div>
						<div class="item item4">
							<img src="../assets/images/wlv_20.png" class="icon" alt="">
							<div class="box">
								<div class="imgbox">
									<img src="../assets/images/wlv_09.webp" class="img" alt="">
								</div>
								<div class="info">
									<h6 class="title">餐馆就餐情况</h6>
									<div class="txt">
										<p>就餐人数:<em class="c-org">1500</em></p>
										<p>就餐完毕:2100</p>
										<p>平均就餐时间:1H/人</p>
									</div>
								</div>
							</div>
						</div>
					</div>
					<ul class="mod3-bot">
						<li>
							<img src="../assets/images/wlv_17.png" class="icon" alt="">
							<span>景区景点</span>
						</li>
						<li>
							<img src="../assets/images/wlv_18.png" class="icon" alt="">
							<span>交通接驳</span>
						</li>
						<li>
							<img src="../assets/images/wlv_19.png" class="icon" alt="">
							<span>住宿</span>
						</li>
						<li>
							<img src="../assets/images/wlv_20.png" class="icon" alt="">
							<span>餐饮</span>
						</li>
						<li>
							<img src="../assets/images/wlv_21.png" class="icon" alt="">
							<span>公共服务</span>
						</li>
					</ul>
				</div>
					
				
			</div>
			<div class="con-r flex flex-v">
				<div class="mod4 flex-1 flex flex-v">
					<div class="b-pub-tt flex">
						<span class="bt">重点景区</span>
					</div>
					<div class="flex flex-pack-justify flex-1">
						<div class="col col1">
							<div class="b-smtit flex flex-pack-justify">
								<span class="bt">重点景区总况</span>
								<span class="smwz">总收入： 277,318.3万元</span>
							</div>
							<ul class="list flex-1">
								<li class="item" v-for="(item,index) in jqPicList" :key="index">
									<h6 class="title">{{item.title}}</h6>
									<img :src="item.img" class="img img_full" alt="">
								</li>
							</ul>
							
						</div>
						<div class="col col2">
							<div class="b-smtit">
								<span class="bt">本月景区客流排名</span>
							</div>
							<ul class="bar-list">
								<li class="row" v-for="(item,index) in jqList" :key="index">
									<span class="num">{{index + 1}}</span>
									<div class="smbox">
										<div class="font">
											<span>{{item.name}}</span>
											<div class="flex">
												<span>{{item.num1}}</span> /
												<span class="c-org">{{item.num2}}</span>
											</div>
										</div>
										<div class="progress"><i :style="{'width': item.widthVar}"></i></div>
									</div>
								</li>
							</ul>
							
						</div>
					</div>
					
				</div>
				<div class="mod5">
					<div class="b-pub-tt flex">
						<span class="bt">本月下辖各镇接待游客</span>
					</div>
					<ul class="list">
						<li class="item1">
							<div class="xh">1</div>
							<div class="info">
								<h6 class="tit">忠诚镇</h6>
								<p class="num" style="color:#489FFE;">10,298</p>
							</div>
						</li>
						<li class="item2">
							<div class="xh">2</div>
							<div class="info">
								<h6 class="tit">栽麻镇</h6>
								<p class="num" style="color:rgb(133, 177, 255);">9,927</p>
							</div>
						</li>
						<li class="item3">
							<div class="xh">3</div>
							<div class="info">
								<h6 class="tit">乐里镇</h6>
								<p class="num" style="color:#85B1FF;">8,900</p>
							</div>
						</li>
						<li class="item4">
							<div class="xh">4</div>
							<div class="info">
								<h6 class="tit">车江乡</h6>
								<p class="num" style="color:#90FFAD;">7,200</p>
							</div>
						</li>
						<li class="item5">
							<div class="xh">5</div>
							<div class="info">
								<h6 class="tit">古州镇</h6>
								<p class="num" style="color:#FFC253;">7,060</p>
							</div>
						</li>
					</ul>
					
				</div>
				
				<div class="mod6">
					<div class="b-pub-tt flex">
						<span class="bt">年度游客来源占比排行</span>
					</div>
					<div class="flex flex-pack-justify">
						<div class="col">
							<div class="b-smtit">
								<span class="bt">省外游客来源排行榜</span>
							</div>
							<ul class="bar-list">
								<li class="row" v-for="(item,index) in wlykList" :key="index">
									<span class="num">{{index + 1}}</span>
									<div class="smbox">
										<div class="font">
											<span>{{item.name}}</span>
											<span>{{item.num}}</span>
										</div>
										<div class="progress"><i :style="{'width': item.widthVar}"></i></div>
									</div>
								</li>
							</ul>
						</div>
						<div class="col">
							<div class="b-smtit">
								<span class="bt">省内游客来源排行榜</span>
							</div>
							<ul class="bar-list">
								<li class="row" v-for="(item,index) in snykList" :key="index">
									<span class="num">{{index + 1}}</span>
									<div class="smbox">
										<div class="font">
											<span>{{item.name}}</span>
											<span>{{item.num}}</span>
										</div>
										<div class="progress"><i :style="{'width': item.widthVar}"></i></div>
									</div>
								</li>
							</ul>
						</div>
						
					</div>
					
				</div>
				
			</div>
		</div>
	</div>
</template>

<script>
	import Head from '@/components/head_big.vue'
	let Echarts = require('echarts/lib/echarts');
	import { loopShowTooltip } from "../assets/js/tooltip-auto-show-vue.js";
	export default {
		data() {
			return {
				headTitle: '龙胜县智慧文旅展示中心',
				fbList:[{
					name:'23岁以下',
					num:'35.4%',
					widthVar:'35.4%'
				},{
					name:'23-60岁',
					num:'56.3%',
					widthVar:'56.3%'
				},{
					name:'60岁以上',
					num:'8.3%',
					widthVar:'8.3%'
				}],
				jqPicList:[{
					title:'龙胜龙脊梯田',
					img:require('../assets/images/wlv_10.gif')
				},{
					title:'龙胜泗水温泉',
					img:require('../assets/images/wlv_11.gif')
				},{
					title:'布尼梯田',
					img:require('../assets/images/wlv_12.gif')
				},{
					title:'南山草原',
					img:require('../assets/images/wlv_10.gif')
				},{
					title:'花坪国家级自然保护区',
					img:require('../assets/images/wlv_11.gif')
				},{
					title:'龙胜马堤乡张家塘苗寨',
					img:require('../assets/images/wlv_12.gif')
				},{
					title:'岩门峡漂流',
					img:require('../assets/images/wlv_10.gif')
				},{
					title:'白面瑶寨',
					img:require('../assets/images/wlv_11.gif')
				},{
					title:'龙脊小镇',
					img:require('../assets/images/wlv_12.gif')
				}],
				jqList:[{
					name:'龙胜龙脊梯田',
					num1:'9.04万人',
					num2:'6162.63万元',
					widthVar:'88%'
				},{
					name:'龙胜泗水温泉',
					num1:'6.70万人',
					num2:'5135.52万元',
					widthVar:'73%'
				},{
					name:'布尼梯田',
					num1:'5.02万人',
					num2:'4108.42万元',
					widthVar:'50%'
				},{
					name:'南山草原',
					num1:'4.69万人',
					num2:'4108.42万元',
					widthVar:'43%'
				},{
					name:'花坪国家级自然保护区',
					num1:'span',
					num2:'3594.87万元',
					widthVar:'28%'
				},{
					name:'龙胜马堤乡张家塘苗寨',
					num1:'2.81万人',
					num2:'3389.45万元',
					widthVar:'13%'
				}],
				wlykList:[{
					name:'济南',
					num:'77万人',
					widthVar:'95%'
				},{
					name:'深圳',
					num:'56万人',
					widthVar:'80%'
				},{
					name:'成都',
					num:'43万人',
					widthVar:'65%'
				},{
					name:'重庆',
					num:'38万人',
					widthVar:'50%'
				},{
					name:'广州',
					num:'22万人',
					widthVar:'35%'
				},{
					name:'长沙',
					num:'19万人',
					widthVar:'20%'
				}],
				snykList:[{
					name:'南宁',
					num:'23万人',
					widthVar:'85%'
				},{
					name:'柳州',
					num:'19万人',
					widthVar:'70%'
				},{
					name:'北海',
					num:'16万人',
					widthVar:'55%'
				},{
					name:'梧州',
					num:'10万人',
					widthVar:'40%'
				},{
					name:'玉林',
					num:'7万人',
					widthVar:'25%'
				},{
					name:'防城港',
					num:'6万人',
					widthVar:'10%'
				}],
				chart1: null,
				tootipTimer1:"",
				chart2: null,
				tootipTimer2:"",
				chart3: null,
				tootipTimer3:"",
				chart4: null,
				tootipTimer4:"",
			}
		},
		components: {
			Head

		},
		computed: {

		},
		created() {

		},
		mounted() {
			this.init1();
			this.init2();
			this.init3();
			this.init4();
			window.addEventListener('resize', () => {
				this.chart1.resize();
				this.chart2.resize();
				this.chart3.resize();
				this.chart4.resize();
			});
		},
		
		methods: {
			
			init1() {
				this.chart1 = Echarts.init(this.$refs.chart1);
				let option = {
					tooltip: {
						trigger: 'axis',
						backgroundColor: 'rgba(0,0,0,.9)',
						textStyle: {
							fontSize: nowSize(12),
							color: '#fff'
						},
						extraCssText: 'border:1px solid #3FF5FF',
						axisPointer: {
							crossStyle: {
								color: '#fff'
							}
						}
					},
					toolbox: {
						show: true
					},
					grid: {
						left: nowSize(20),
						right: nowSize(40),
						bottom: nowSize(10),
						top: nowSize(60),
						containLabel: true
					},

					legend: {
						x: 'right',
						y: 'top',
						icon:'rect',
						itemWidth:nowSize(14),
						itemHeight:nowSize(14),
						textStyle: {
							color: '#fff',
							fontSize: nowSize(18)
						},
					},
					xAxis: [{
						type: 'category',
						boundaryGap: false,
						data: ['2021年11月24日', '2021年11月25日','2021年11月26日','2021年11月27日','2021年11月28日','2021年11月29日','2021年11月30日',],
						axisPointer: {
							type: ''
						},
						axisLabel: {
							show: true,
							textStyle: {
								color: '#fff',
								fontSize: nowSize(18)
							},
							//rotate: '0',
							interval: 0
						},
						axisTick: {
							show: false
						},
						axisLine: {
							show: true,
							lineStyle: {
								color: '#023748' //更改坐标轴颜色
							}
						},
						splitLine: {
							show: false,
							lineStyle: {
								color: ['#edf2f8'],
								width: 1,
								type: 'dashed'
							}
						}
					}],
					yAxis: [{
						type: 'value',
						name: '单位:人次(千)',
						position: 'left',
						min: 0,
						max: 30000,
						interval: 5000,
						nameTextStyle: {
							color: "#fff",
							fontSize: nowSize(18),
							padding: [0, 0, 0, -20]
						},
						axisLabel: {
							show: true,
							textStyle: {
								color: '#fff', //更改坐标轴文字颜色
								fontSize: nowSize(18), //更改坐标轴文字大小
							},
							//formatter: '{value}%'
						},
						axisTick: {
							show: false
						},
						axisLine: {
							show: true,
							lineStyle: {
								color: '#023748' //更改坐标轴颜色
							}
						},
						splitLine: {
							show: true,
							lineStyle: {
								color: ['#023748'],
								width: 1,
								type: 'solid'
							}
						}
					}],
			
					series: [{
						name: '预计客流',
						type: 'line',
						data: [18753,21432,22102,24000,25450,28799,26120],
						smooth: true,
						symbolSize: 1, //折线点的大小
						itemStyle: {
							normal: {
								color: "#FD9D20", //折线点的颜色
								borderWidth: 1,
								lineStyle: {
									color: "#FD9D20" //折线的颜色
			
								},
								areaStyle: {
									color: new Echarts.graphic.LinearGradient(0, 1, 0, 0, [{
									  offset: 0,
									  color: 'rgba(235,157,32,0)'
									}, {
									  offset: 1,
									  color: 'rgba(235,157,32,.6)'
									}]),
								  }
								
							}
						}
					}]
			
				};
				this.chart1.setOption(option, true);
				
				this.tootipTimer1 && this.tootipTimer1.clearLoop(); 
				this.tootipTimer1 = 0;
				this.tootipTimer1 = loopShowTooltip(this.chart1, option, {});
				
			},
			
			init2() {
				this.chart2 = Echarts.init(this.$refs.chart2);
				let option = {
					tooltip: {
						trigger: 'axis',
						backgroundColor: 'rgba(0,0,0,.9)',
						textStyle: {
							fontSize: nowSize(12),
							color: '#fff'
						},
						extraCssText: 'border:1px solid #3FF5FF',
						axisPointer: {
							crossStyle: {
								color: '#fff'
							}
						}
					},
					toolbox: {
						show: true
					},
					grid: {
						left: nowSize(20),
						right: nowSize(40),
						bottom: nowSize(10),
						top: nowSize(40),
						containLabel: true
					},
			
					legend: {
						x: 'right',
						y: 'top',
						icon:'rect',
						itemWidth:nowSize(14),
						itemHeight:nowSize(14),
						textStyle: {
							color: '#fff',
							fontSize: nowSize(18)
						},
					},
					xAxis: [{
						type: 'category',
						//boundaryGap: false,
						data: ['杭州','上海','湖州','苏州','无锡','南京',],
						axisPointer: {
							type: ''
						},
						axisLabel: {
							show: true,
							textStyle: {
								color: '#fff',
								fontSize: nowSize(18)
							},
							//rotate: '0',
							interval: 0
						},
						axisTick: {
							show: false
						},
						axisLine: {
							show: true,
							lineStyle: {
								color: '#023748' //更改坐标轴颜色
							}
						},
						splitLine: {
							show: false,
							lineStyle: {
								color: ['#edf2f8'],
								width: 1,
								type: 'dashed'
							}
						}
					}],
					yAxis: [{
						type: 'value',
						name: '',
						position: 'left',
						min: 0,
						max: 12000,
						interval: 3000,
						nameTextStyle: {
							color: "#fff",
							fontSize: nowSize(20),
							padding: [0, 0, 0, -20]
						},
						axisLabel: {
							show: true,
							textStyle: {
								color: '#fff', //更改坐标轴文字颜色
								fontSize: nowSize(18), //更改坐标轴文字大小
							},
							//formatter: '{value}%'
						},
						axisTick: {
							show: false
						},
						axisLine: {
							show: true,
							lineStyle: {
								color: '#023748' //更改坐标轴颜色
							}
						},
						splitLine: {
							show: true,
							lineStyle: {
								color: ['#023748'],
								width: 1,
								type: 'solid'
							}
						}
					}],
			
					series: [{
						name: '',
						type: 'bar',
						stack: 'Total',
						label: {
						    show: true,
						    position: 'top',
							textStyle: {
								color: '#fff',
								fontSize: nowSize(18)
							},
						},
						showBackground: false,
						barWidth: nowSize(16),
						data: [10046,1340,1340,2009,6698,6698],
						itemStyle: {
							normal: {
								color: "#45F5FF",
								barBorderRadius: 0
							}
						}
					}]
			
				};
				this.chart2.setOption(option, true);
				
				this.tootipTimer2 && this.tootipTimer2.clearLoop(); 
				this.tootipTimer2 = 0;
				this.tootipTimer2 = loopShowTooltip(this.chart2, option, {});
				
			},
			
			
			init3() {
				this.chart3 = Echarts.init(this.$refs.chart3);
				const chartData = [
					{
						value: 368362.5,
						name: '男',
					},
					{
						value: 301387.5,
						name: '女'
					}
				];
				const sum = chartData.reduce((per, cur) => per + cur.value, 0)
				let option = {
					tooltip: {
						trigger: 'item',
					},
					grid: {
						left: '0',
						right: '0',
						bottom: '0',
						top: '0',
						containLabel: true
					},
					legend: {
						x: '60%',
						y: 'center',
						orient: 'vertial',
						icon: 'rect',
						itemWidth: nowSize(16),
						itemHeight: nowSize(16),
						textStyle: {
							color: '#fff',
							fontSize: nowSize(18),
							lineHeight: nowSize(30)
						},
						itemGap:  nowSize(15),
						formatter: name => {
							console.log(name)
							console.log(chartData)
							const item = chartData.find(i => {
								return i.name === name
							})
							const p = ((item.value / sum) * 100).toFixed(0)
							const value = item.value
							return '{name|' + name + '}' + '{value|' + value + '万元}' + '{percent|' + p + '%}' 
						},
						textStyle: {
							 rich: {
								name: {
								   fontSize: nowSize(18),
								   width: nowSize(50),
								   height: nowSize(20),
								   padding: [0, 0, 0, 5],
								   color: '#fff',
								},
								value: {
								   fontSize: nowSize(18),
								   width: nowSize(110),
								   height: nowSize(20),
								   color: '#45F5FF',
								},
								percent: {
								   fontSize: nowSize(18),
								   //width: nowSize(110),
								   height: nowSize(20),
								   color: '#fff',
								},
							 }
						  }
					},
					color: ['#3a76fb', '#ff9b1b'],
					series: [{
						name: '',
						type: 'pie',
						radius: ['50%', '65%'],
						center: ['28%', '48%'],
						avoidLabelOverlap: false,
						hoverAnimation: true,
						label: {
							normal: {
								show: false
							}
						},
						itemStyle: {
							borderRadius: 0,
							borderColor: '#fff',
							borderWidth: 0
						},
						labelLine: {
							show: false
						},
						data:chartData
					}]
			
				};
				this.chart3.setOption(option, true);
				
				
				
				this.tootipTimer3 && this.tootipTimer3.clearLoop();
				this.tootipTimer3 = 0;
				this.tootipTimer3 = loopShowTooltip(this.chart3, option, {});
			},
			
			
			
				
			init4() {
				this.chart4 = Echarts.init(this.$refs.chart4);
				const chartData = [
					{
						value: 184878.8,
						name: '23岁以下',
					},
					{
						value: 338944.5,
						name: '23-60岁'
					},
					{
						value: 503281.3,
						name: '60岁以上'
					}
				];
				const sum = chartData.reduce((per, cur) => per + cur.value, 0)
				let option = {
					title: {
						text: '{a|' + sum + '}\n\n{b|万元}',
						top: 'center',
						textStyle: {
							 rich: {
								a: {
								   fontSize: nowSize(20),
								   padding: [0, 0, 0, nowSize(93)],
								   color: '#45F5FF',
								   lineHeight:1,
								    fontFamily:'PangMenZhengDao',
								},
								b: {
									fontSize: nowSize(18),
									padding: [nowSize(10), 0, 0, nowSize(125)],
									color: '#fff',
									lineHeight:1,
									fontFamily:'PangMenZhengDao'
								}
							}
						}
				   },
					tooltip: {
						trigger: 'item',
					},
					grid: {
						left: '0',
						right: '0',
						bottom: '0',
						top: '0',
						containLabel: true
					},
					legend: {
						x: '60%',
						y: 'center',
						orient: 'vertial',
						icon: 'rect',
						itemWidth: nowSize(16),
						itemHeight: nowSize(16),
						textStyle: {
							color: '#fff',
							fontSize: nowSize(18),
							lineHeight: nowSize(30)
						},
						itemGap:  nowSize(15),
						formatter: name => {
							console.log(name)
							console.log(chartData)
							const item = chartData.find(i => {
								return i.name === name
							})
							const p = ((item.value / sum) * 100).toFixed(0)
							const value = item.value
							return '{name|' + name + '}' + '{value|' + value + '万元}' + '{percent|' + p + '%}' 
						},
						textStyle: {
							 rich: {
								name: {
								   fontSize: nowSize(18),
								   width: nowSize(80),
								   height: nowSize(20),
								   padding: [0, 0, 0, 5],
								   color: '#fff',
								},
								value: {
								   fontSize: nowSize(18),
								   width: nowSize(110),
								   height: nowSize(20),
								   color: '#45F5FF',
								},
								percent: {
								   fontSize: nowSize(18),
								   //width: nowSize(110),
								   height: nowSize(20),
								   color: '#fff',
								},
							 }
						  }
					},
					color: ['#00bb86', '#3a76fb', '#ff9b1b'],
					series: [{
						name: '',
						type: 'pie',
						radius: ['50%', '65%'],
						center: ['28%', '48%'],
						avoidLabelOverlap: false,
						hoverAnimation: true,
						label: {
							normal: {
								show: false
							}
						},
						itemStyle: {
							borderRadius: 0,
							borderColor: '#fff',
							borderWidth: 0
						},
						labelLine: {
							show: false
						},
						data:chartData
					},{
						 type: 'pie',
						 name: '内层细圆环',
						radius: ['42%', '44%'],
						center: ['28%', '48%'],
						 hoverAnimation: false,
						 clockWise: true,
						 itemStyle: {
							normal: {
							   color: '#178ac9',
							},
						 },
						 tooltip: {
							show: false,
						 },
						 label: {
							show: false,
						 },
						 data: [1],
					}]
			
				};
				this.chart4.setOption(option, true);
				
				
				
				// this.tootipTimer4 && this.tootipTimer4.clearLoop();
				// this.tootipTimer4 = 0;
				// this.tootipTimer4 = loopShowTooltip(this.chart4, option, {});
			},
				
				
		}
	}
</script>

<style scoped>
.page{background:url(../assets/images/wlv_22.png) no-repeat center;background-size:100% 100%;}
	
.mod1{margin-bottom: 15px;height:44vh;}
.mod1 .list{display: flex;justify-content: space-between;padding-right: 40px;min-height: 8vh;align-items: center;}
.mod1 .list li{display: flex;align-items: center;}
.mod1 .list .icon{width: 100px;margin-right: 10px;}
.mod1 .list .info{flex:1;overflow: hidden;}
.mod1 .list .tit{font-size: 24px;color:#fff;}
.mod1 .list .num{font-size: 28px;color:#fff;}


.mod2{height: 55vh;}
.mod2 .list{display: flex;justify-content: space-between;flex-wrap: wrap;}	
.mod2 .list .item{width: 48%;height:calc(50% - 15px);}
.mod2 .list .item:nth-child(1),.mod2 .list .item:nth-child(2){margin-bottom: 20px;}
.mod2 .list .chart{width:100%;height: calc(100% - 50px);}	

.mod2 .fenbu{width:100%;height: calc(100% - 50px);}	


.con-c{position:relative;}
	
.mod3-bot{display: flex;justify-content: space-around;position:absolute;width: 100%;left: 0;bottom: 30px;padding:0 4%;z-index:10;}
.mod3-bot li{display: flex;align-items: center;}
.mod3-bot .icon{width: 44px;margin-right: 16px;}
.mod3-bot li span{font-size: 26px;color:#fff;font-family: 'RuiZiZhenYan';letter-spacing: 3px;}

.mod3-map{position:absolute;width: calc(100% + 300px);height: 100%;left: 0;top: 0;z-index:1;margin-left: -150px;}
.mod3-map .pic{width:100%;height: 100%;max-width:initial;}
.mod3-map .item{position:absolute;width: 430px;}
.mod3-map .item .icon{width: 60px;}
.mod3-map .item .box{display: flex;background-color:rgba(28,42,52,.7);border:1px solid #95A1AB;padding: 20px;border-radius:10px;align-items: center;}
.mod3-map .item .imgbox{width: 100px;height: 100px;border-radius:8px;margin-right: 20px;border:1px solid #95A1AB;overflow: hidden;}
.mod3-map .item .img{width: 100%;height: 100%;object-fit: cover;}
.mod3-map .item .info{flex:1;overflow: hidden;}
.mod3-map .item .title{font-size: 22px;color:#fff;margin-bottom: 6px;}
.mod3-map .item .txt{font-size: 18px;color:#fff;line-height: 1.5;}

.mod3-map .item1{top: 50%;left: 10%;}
.mod3-map .item2{top: 40%;left: 45%;}
.mod3-map .item3{top: 20%;left: 15%;}
.mod3-map .item4{top: 65%;left: 55%;}





.mod4{padding-bottom: 15px;max-height:38vh;}
.mod4 .col{bottom: 100%;display: flex;flex-direction: column;}
.mod4 .col1{width: 50%;}
.mod4 .col2{width: 47%;}

.mod4 .list{display: grid;flex-wrap: wrap;grid-template-columns: repeat(3,auto);grid-template-rows: repeat(3,auto);grid-gap: 15px;}
.mod4 .list .item{position:relative;/* width: 185px;height: 160px; *//* margin:0 0 25px 15px; */ }
.mod4 .list .title{position:absolute;width: 100%;left: 0;top: 0;background-color: #15386e;padding:5px 5px;line-height:24px;text-align: center;font-size: 14px;color:#fff;}

	
.mod5{height: 14vh;}	
.mod5 .b-pub-tt{margin-bottom: 10px;}	
.mod5 .list{display: flex;justify-content: space-between;padding-right: 40px;}
.mod5 .list li{display: flex;align-items: center;}
.mod5 .list .xh{width: 100px;margin-right: 5px;height:100px;background-repeat:no-repeat;background-position:center;background-size:100% 100%;display: flex;align-items: center;justify-content: center;font-family: 'PangMenZhengDao';font-size: 28px;color:#fff;}
.mod5 .list .info{flex:1;overflow: hidden;}
.mod5 .list .tit{font-size: 22px;color:#fff;}
.mod5 .list .num{font-size: 26px;color:#fff;font-family: 'PangMenZhengDao';margin-top: 5px;}
	
.mod5 .list .item1 .xh{background-image:url(../assets/images/wlv_13.png);}
.mod5 .list .item2 .xh{background-image:url(../assets/images/wlv_14.png);}
.mod5 .list .item3 .xh{background-image:url(../assets/images/wlv_15.png);}
.mod5 .list .item4 .xh{background-image:url(../assets/images/wlv_16.png);}
.mod5 .list .item5 .xh{background-image:url(../assets/images/wlv_23.png);}


.mod6{}
.mod6 .col{width: 46%;}





</style>
